import React from 'react';
import { AppBar, Toolbar, Typography, Container, Box } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import NDAForm from './components/NDAForm';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Legal bot
          </Typography>
        </Toolbar>
      </AppBar>
      <Container>
        <Box my={4}>
          <NDAForm />
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default App;