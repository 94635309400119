import React from 'react';
import { Box, Typography, Divider, Accordion, AccordionSummary, AccordionDetails, Container, Grid, Paper } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Warning, CheckCircle } from '@mui/icons-material';

const titleMapping = {
  bilateral_agreement: 'Двустороннее соглашение',
  ci_transferable: 'Передачи КИ третьим лицам',
  law_russia: 'Применимое право',
  jurisdiction_asgm: 'Подсудность АСГМ',
  disclosure_allowed: 'КИ: Раскрытие для маркетинга',
  ci_written: 'КИ: Письменная форма',
  ci_marked: 'КИ: Маркировка',
  lost_profit_compensated: 'Возмещение упущенной выгоды',
  max_liability_set: 'Максимальный размер ответственности',
  penalties: 'Штрафы',
  overall_ci_term: 'КИ: Срок хранения в тайне',
};

const NDAResponse = ({ response }) => {
  const riskEntries = Object.entries(response).filter(([key, value]) => !value.answer);
  const noRiskEntries = Object.entries(response).filter(([key, value]) => value.answer);

  return (
    <Container maxWidth="lg"> {/* Increased width */}
      <Box my={4} px={2}> {/* Added padding */}
        <Typography variant="h5" component="h2" gutterBottom>
          NDA Review Results
        </Typography>
        <Divider sx={{ mb: 2 }} />

        <Grid container spacing={2}>
          {riskEntries.map(([key, value]) => (
            <Grid item xs={12} key={key}>
              <Paper elevation={3} sx={{ backgroundColor: '#ffebee', padding: 2, borderRadius: 1 }}>
                <Typography variant="h6" component="h3" gutterBottom>
                  {titleMapping[key] || key.replace(/_/g, ' ')}
                </Typography>
                <Typography variant="body1" color="red" sx={{ ml: 2, display: 'flex', alignItems: 'center' }}>
                  <Warning sx={{ mr: 1 }} /> Risk
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {value.reasoning}
                </Typography>
              </Paper>
            </Grid>
          ))}

          {noRiskEntries.map(([key, value]) => (
            <Grid item xs={12} key={key}>
              <Accordion sx={{ mb: 2, borderRadius: 1, boxShadow: 3 }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`${key}-content`}
                  id={`${key}-header`}
                  sx={{ backgroundColor: '#f5f5f5' }}
                >
                  <Typography variant="h6" component="h3">
                    {titleMapping[key] || key.replace(/_/g, ' ')}
                  </Typography>
                  <Typography variant="body1" color="green" sx={{ ml: 2, display: 'flex', alignItems: 'center' }}>
                    <CheckCircle sx={{ mr: 1 }} /> No risk
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" color="textSecondary">
                    {value.reasoning}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default NDAResponse;