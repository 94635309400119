import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { 
  TextField, 
  Button, 
  Box, 
  CircularProgress, 
  LinearProgress, 
  IconButton, 
  List, 
  ListItem, 
  ListItemText, 
  Typography 
} from '@mui/material';
import { UploadFile, Delete } from '@mui/icons-material';

const NDAFormFields = ({ setMessage, setResponse }) => {
  const [text, setText] = useState('');
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      setMessage({ 
        type: 'error', 
        content: `Invalid file type. Only .pdf, .doc, and .docx files are allowed.` 
      });
    } else {
      setFile(acceptedFiles[0]);
      setMessage({ type: null, content: null });
    }
  }, [setMessage]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.pdf,.doc,.docx',
    maxSize: 5 * 1024 * 1024, // 5MB file size limit
  });

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleRemoveFile = () => {
    setFile(null);
    setMessage({ type: null, content: null }); 
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!text && !file) {
      setMessage({ type: 'error', content: 'Please provide either text or a file.' });
      return;
    }

    setLoading(true);
    setMessage({ type: null, content: null });
    setResponse(null);

    const formData = new FormData();
    formData.append('text', text);
    if (file) {
      formData.append('contract_file', file);
    }

    try {
      const response = await axios.post('/api/v1/nda/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        timeout: 180000, // 3 minutes
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          setUploadProgress(progress);
        },
      });

      setMessage({ type: 'success', content: 'NDA submitted successfully!' });
      setText('');
      setFile(null);
      setResponse(response.data);
    } catch (error) {
      setMessage({
        type: 'error',
        content: error.response?.data?.detail || 'Error submitting NDA.', 
      });
    } finally {
      setLoading(false);
      setUploadProgress(0);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label="NDA Text"
        multiline
        rows={4}
        variant="outlined"
        fullWidth
        value={text}
        onChange={handleTextChange}
        margin="normal"
        placeholder="Paste NDA text here..."
        sx={{ mb: 2 }}
        disabled={!!file} // Disable if a file is selected
      />
      {file && (
        <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
          Note: The uploaded file will be used as the NDA, even if text is entered above.
        </Typography>
      )}

      <Box
        {...getRootProps()}
        sx={{
          border: '2px dashed #1976d2',
          borderRadius: '4px',
          padding: '16px',
          textAlign: 'center',
          cursor: 'pointer',
          backgroundColor: isDragActive ? '#e3f2fd' : 'inherit', // Light blue on drag active
          '&:hover': {
            backgroundColor: '#e3f2fd', // Light blue on hover
          },
          mb: 2
        }}
      >
        <input {...getInputProps()} />
        <UploadFile sx={{ fontSize: 40, color: 'action.active', mb: 1 }} />
        <Typography variant="body1" color="textSecondary">
          {isDragActive
            ? 'Drop the file here ...'
            : `Drag 'n' drop a file here, or click to select a file (.pdf, .doc, .docx, max 5MB)`}
        </Typography>
      </Box>

      {file && (
        <List>
          <ListItem
            secondaryAction={
              <IconButton edge="end" aria-label="delete" onClick={handleRemoveFile}>
                <Delete />
              </IconButton>
            }
          >
            <ListItemText primary={file.name} />
          </ListItem>
        </List>
      )}
      {loading && uploadProgress > 0 && (
        <Box sx={{ width: '100%', mt: 2 }}>
          <LinearProgress variant="determinate" value={uploadProgress} />
        </Box>
      )}

      <Box mt={2}>
        <Button type="submit" variant="contained" color="primary" disabled={loading} fullWidth>
          {loading ? <CircularProgress size={24} /> : 'Submit'}
        </Button>
      </Box>
    </form>
  );
};

export default NDAFormFields;