import React, { useState } from "react";
import {
  Container,
  Box,
  Card,
  CardContent,
  Typography,
  Alert,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import NDAFormFields from "./NDAFormFields";
import NDAResponse from "./NDAResponse";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#dc004e",
    },
  },
});

const NDAForm = () => {
  const [message, setMessage] = useState({ type: null, content: null });
  const [response, setResponse] = useState(null);

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm">
        <Box my={4}>
          <Card>
            <CardContent>
              <Typography
                variant="h4"
                component="h1"
                gutterBottom
                sx={{ fontWeight: "bold", mb: 2 }}
              >
                Submit NDA for Review
              </Typography>
              {message.content && (
                <Alert severity={message.type} sx={{ mb: 2 }}>
                  {message.content}
                </Alert>
              )}
              <NDAFormFields
                setMessage={setMessage}
                setResponse={setResponse}
              />
              {response && <NDAResponse response={response} />}
            </CardContent>
          </Card>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default NDAForm;
